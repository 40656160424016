import React from 'react';
import { Check, Code, PieChart, Users, Brain, FileText, Globe, Shield, Briefcase, Scale, Microscope, Zap, BookOpen, HeartPulse, Megaphone, Truck } from 'lucide-react';

const PricingCard = ({ model, features, inputPrice, outputPrice }) => (
  <div className="mb-8 p-6 bg-white rounded-lg shadow-md">
    <h2 className="text-2xl font-bold mb-2">{model}</h2>
    {features.map((feature, index) => (
      <div key={index} className="flex items-center mb-2">
        <Check className="text-green-500 mr-2" size={20} />
        <span>{feature}</span>
      </div>
    ))}
    <div className="mt-4 flex justify-between">
      <div>
        <p className="text-sm text-gray-600">Input</p>
        <p className="text-lg font-semibold">{inputPrice}</p>
      </div>
      <div>
        <p className="text-sm text-gray-600">Output</p>
        <p className="text-lg font-semibold">{outputPrice}</p>
      </div>
    </div>
  </div>
);

const PlanCard = ({ title, description, features, buttonText, icon }) => (
  <div className="flex-1 p-6 bg-white rounded-lg shadow-md border border-gray-200">
    <div className="mb-4">{icon}</div>
    <h2 className="text-2xl font-bold mb-2">{title}</h2>
    <p className="mb-4 text-gray-600">{description}</p>
    <ul className="mb-6">
      {features.map((feature, index) => (
        <li key={index} className="flex items-start mb-2">
          <Check className="text-green-500 mr-2 mt-1 flex-shrink-0" size={16} />
          <span>{feature}</span>
        </li>
      ))}
    </ul>
    <button className="w-full py-2 px-4 bg-red-600 text-white rounded hover:bg-red-700 transition duration-200">
      {buttonText}
    </button>
  </div>
);

const UseCase = ({ icon, title, description }) => (
  <div className="flex items-start p-6 bg-white rounded-lg shadow-md mb-6">
    <div className="flex-shrink-0 mr-4 p-3 bg-gray-100 rounded-lg">
      {icon}
    </div>
    <div>
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  </div>
);

const Pricing = () => {
  return (
    <div className="max-w-6xl mx-auto p-6">
      <h1 className="text-4xl font-bold mb-8 text-center text-red-600">UltraSafe AI - Pricing</h1>
      <h2 className="text-2xl font-semibold mb-6">Explore latest expert models</h2>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
        <PricingCard
          model="EUS Mega (multimodal)"
          features={[
            "Advanced AI model for complex tasks",
            "Extensive context understanding"
          ]}
          inputPrice="$2 / Million Tokens"
          outputPrice="$5 / Million Tokens"
        />
        
        <PricingCard
          model="EUS Flash (text)"
          features={[
            "High-speed model for rapid processing",
            "Efficient for small-scale applications"
          ]}
          inputPrice="$0.10 / Million Tokens"
          outputPrice="$0.39 / Million Tokens"
        />
      </div>

      <h2 className="text-3xl font-bold mb-8 text-center">Select Your Solution</h2>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
        <PlanCard
          title="Enterprise"
          description="Empower your organization with a comprehensive AI solution tailored for large-scale operations and innovation."
          features={[
            "Full access to all EUS models",
            "Elastic usage-based pricing",
            "Seamless integration with existing systems",
            "Advanced analytics and reporting tools",
            "Dedicated account management",
            "Extensive documentation and API support"
          ]}
          buttonText="Request a Demo"
          icon={
            <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="w-12 h-12">
              <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5" />
            </svg>
          }
        />

        <PlanCard
          title="Custom Solutions"
          description="Collaborate with our team to develop a bespoke AI solution that addresses your unique business challenges and objectives."
          features={[
            "Tailored AI model development",
            "Customized rate limits and performance optimization",
            "On-premise deployment options",
            "Comprehensive security and compliance measures",
            "Ongoing consultation and strategic partnership",
            "Thrid party API integrations"
          ]}
          buttonText="Contact Our Team"
          icon={
            <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="w-12 h-12">
              <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z" />
              <polyline points="3.27 6.96 12 12.01 20.73 6.96" />
              <line x1="12" y1="22.08" x2="12" y2="12" />
            </svg>
          }
        />
      </div>

      <h2 className="text-3xl font-bold my-12 text-center">Use cases for UltraSafe AI</h2>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        <UseCase
          icon={<Code size={24} />}
          title="Software Development"
          description="Accelerate coding processes with AI-assisted development, code review, and bug detection across multiple programming languages."
        />
        <UseCase
          icon={<PieChart size={24} />}
          title="Business Intelligence"
          description="Transform raw data into actionable insights with advanced analytics, predictive modeling, and automated report generation."
        />
        <UseCase
          icon={<Users size={24} />}
          title="Customer Experience"
          description="Enhance support with 24/7 AI-driven assistance, personalized recommendations, and efficient query resolution."
        />
        <UseCase
          icon={<Brain size={24} />}
          title="Research & Innovation"
          description="Accelerate scientific discoveries and R&D processes with AI-powered literature analysis and hypothesis generation."
        />
        <UseCase
          icon={<FileText size={24} />}
          title="Content Creation"
          description="Streamline content production with AI-assisted writing, editing, and SEO optimization for various media formats."
        />
        <UseCase
          icon={<Globe size={24} />}
          title="Language Services"
          description="Break language barriers with advanced translation, localization, and cross-cultural communication assistance."
        />
        <UseCase
          icon={<Shield size={24} />}
          title="Cybersecurity"
          description="Strengthen digital defenses with AI-driven threat detection, vulnerability assessment, and incident response planning."
        />
        <UseCase
          icon={<Briefcase size={24} />}
          title="Human Resources"
          description="Optimize HR processes with AI-assisted recruitment, employee onboarding, and performance management systems."
        />
        <UseCase
          icon={<Scale size={24} />}
          title="Legal & Compliance"
          description="Enhance legal operations with AI-powered contract analysis, regulatory compliance checking, and case law research."
        />
        <UseCase
          icon={<Microscope size={24} />}
          title="Healthcare & Life Sciences"
          description="Advance medical research and patient care with AI-assisted diagnostics, treatment planning, and drug discovery."
        />
        <UseCase
          icon={<Zap size={24} />}
          title="Energy Management"
          description="Optimize energy consumption and distribution with AI-driven predictive maintenance and smart grid management."
        />
        <UseCase
          icon={<BookOpen size={24} />}
          title="Education & E-learning"
          description="Personalize learning experiences with AI-powered tutoring, adaptive assessments, and curriculum development."
        />
        <UseCase
          icon={<HeartPulse size={24} />}
          title="Mental Health Support"
          description="Provide accessible mental health resources with AI-driven therapy assistance and mood tracking tools."
        />
        <UseCase
          icon={<Megaphone size={24} />}
          title="Marketing & Advertising"
          description="Enhance campaign effectiveness with AI-powered audience targeting, content optimization, and performance analytics."
        />
        <UseCase
          icon={<Truck size={24} />}
          title="Supply Chain Optimization"
          description="Streamline logistics and inventory management with AI-driven demand forecasting and route optimization."
        />
      </div>
    </div>
  );
};

export default Pricing;